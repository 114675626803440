import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useIntl } from "gatsby-plugin-react-intl"
import { setSeoData } from "../services/seo.service"
import { getCarouselImageOG } from "../components/functions"
import "../components/cms/cms.scss"
import MainBannerImage from "../components/common/mainBanner/mainBannerImage"
import Breadcrumbs from "../components/common/breadcrumbs/breadcrumbs"
import BlockBooking from "../components/common/blockBooking/blockBooking";

export default function Cms({ data: { nodePage: page }, pageContext }) {
  const isSSR = typeof window === "undefined"

  const CmsIntro = React.lazy(() => import("../components/cms/cmsIntro"))
  const BlockWysiwyg = React.lazy(() =>
    import("../components/cms/blockWysiwyg")
  )

  const {
    title,
    field_seo,
    page_body: body,
    relationships: {
      page_introduction: pageIntro,
      page_carousel: {
        relationships: { carousel_highlight_item: carouselMea },
      },
    },
  } = page

  const intl = useIntl()

  const carouselFiltered = carouselMea.filter(
    carousel => carousel.relationships.media !== null
  )

  const seoData = setSeoData({
    lang: intl.locale,
    title: title,
    field_seo: field_seo,
    image: process.env.GATSBY_URL + getCarouselImageOG(carouselFiltered),
  })

  return (
    <Layout pageContext={pageContext} pageTitle={title} pageClass="pageCms">
      <Seo data={seoData} pageContext={pageContext} />
      {carouselFiltered && (
        <MainBannerImage items={carouselFiltered} isHeightSmall={1} />
      )}
      {!isSSR && (
        <React.Suspense fallback="loading.... ">
          <Breadcrumbs
            hotelTaxonomyDrupalId={pageContext.hotelTaxonomyDrupalId}
            pageTitle={title}
          />
          {pageIntro && <CmsIntro slice={pageIntro} />}
          <BlockWysiwyg text={body.value} />
        </React.Suspense>
      )}
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    nodePage(id: { eq: $id }) {
      title
      field_seo {
        description
        title
        og_description
        og_image
        og_locale
        og_url
        og_title
        og_type
      }
      relationships {
        page_carousel {
          relationships {
            carousel_highlight_item {
              title
              caption
              subtitle
              cta {
                title
                uri
                url
              }
              relationships {
                media {
                  ... on media__image {
                    field_image {
                      alt
                    }
                    internal {
                      type
                    }
                    relationships {
                      field_image {
                        gatsbyImage(breakpoints: [1920], width: 1920, formats: WEBP, layout: FULL_WIDTH)
                        bannerThumb: gatsbyImage(breakpoints: [800], width: 800, height: 450, formats: WEBP, layout: FULL_WIDTH)
                      }
                    }
                  }
                  ... on media__wistia {
                    internal {
                      type
                    }
                    field_media_oembed_video
                    relationships {
                      thumbnail {
                        gatsbyImage(width: 1920, formats: WEBP)
                      }
                    }
                  }
                }
              }
            }
          }
        }
        page_introduction {
          ...intro
        }
      }
      page_body {
        value
      }
    }
  }
`
