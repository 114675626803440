import React from "react"
import ReactPlayer from "react-player/lazy"

const VideoBackground = props => (
  <div className="video-background">
    <div className="video-foreground">
      {props.type === "media__video" ? (
        <ReactPlayer
          url={props.video}
          playing={true}
          loop={true}
          muted={true}
          className="bg-video"
          width="100%"
          height="100%"
        />
      ) : (
        <ReactPlayer
          url={props.video}
          className="video-iframe"
          playing={props.autoPlay ? props.autoPlay : false}
          config={{
            wistia: {
              options: {
                autoPlay: true,
                endVideoBehavior: "loop",
                fitStrategy: "contain",
                volume: 0,
                videoFoam: true,
                volumeControl: false,
              },
            },
          }}
        />
      )}
    </div>
  </div>
)

export default VideoBackground
