import React, { useEffect, useState } from "react"
import Slider from "react-slick"
import { Container, Row, Col } from "react-bootstrap"
import Title from "../title"
import "./mainBanner.scss"
import StyledButton from "../styledButton"
import { graphql, Link } from "gatsby"
import VideoBackground from "../videoBackground"
import { useIntl } from "gatsby-plugin-react-intl"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import Popup from "../popup/popup"
import ReactPlayer from "react-player"

const MainBannerImage = props => {
  const intl = useIntl()

  const [isOpen, setIsOpen] = useState(false)

  const togglePopup = () => {
    setIsOpen(!isOpen)
  }

  const settings = {
    autoplay: false,
    dots: true,
    infinite: true,
    fade: true,
    speed: 1500,
    lazyLoad: true,
    autoplaySpeed: 3500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  }

  const lists = props.items || {}
  const heightSmall = props.isHeightSmall ? "height-small" : ""
  // Get Mobile
  const window1 = typeof window !== "undefined" ? window : ""

  const [width, setWidth] = useState(window1.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window1.innerWidth)
  }
  useEffect(() => {
    window1.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window1.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [])

  let isMobile = width <= 640

  const bannerItems = lists.reduce((list, current, index) => {
    const bannerText = (
        <Container key={index}>
          <Row className={current.relationships.full_screen_video?.field_media_oembed_video ? "" : "noPopupVid"}>
            <Col md={10} xs={12}>
              <div>
                <span className="caption">{current.caption} </span>
                <Title
                  heading={index === 0 ? 1 : 2}
                  size={2}
                  extraClass="mainTitle"
                  text={current.title}
                />
                <div className="description">{current.subtitle}</div>
                {current.cta && (
                  <div>
                    <StyledButton
                      title={current.cta.title}
                      type="link"
                      path={current.cta.url}
                    />
                  </div>
                )}
              </div>
              {current.relationships.full_screen_video
                ?.field_media_oembed_video && (
                <div>
                    <div>
                      <a className="simpleLink" onClick={togglePopup}>
                        <i className="icon-play"></i>
                      </a>
                    </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
    )

    let backgroundImage = "",
      imageMob = "",
      imageAlt = ""

    if (
      current.relationships.media.relationships.thumbnail &&
      current.relationships.media.relationships.thumbnail.gatsbyImage
    ) {
      // Wistia thumbnail
      backgroundImage =
        getSrc(current.relationships.media.relationships.thumbnail.gatsbyImage)
    } else if (current.relationships.media.relationships.field_image) {
      // Image
      backgroundImage =
        getSrc(current.relationships.media.relationships.field_image.gatsbyImage)

      imageMob =
        current.relationships.media.relationships.field_image.bannerThumb
      imageAlt = current.relationships.media.field_image.alt
    }

    const styleBg = !isMobile
      ? "linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 75%), url(" +
        backgroundImage
      : "none"

    if (current.relationships.media.internal.type === "media__wistia") {
      list.push(
        <div className="slider-element video">
          <div
            className="mainBanner-infos"
          >
            {bannerText}
            <VideoBackground
              video={current.relationships.media.field_media_oembed_video}
              type={current.relationships.media.internal.type}
              autoPlay={isOpen ? false : true}
            />
          </div>
        </div>
      )
    } else {
      list.push(
        <div className="slider-element">
          <div
            className="mainBanner-infos"
            style={{
              backgroundImage: styleBg + ")",
            }}
          >
            {isMobile ? (
              <div className="mobImage">
                <GatsbyImage image={getImage(imageMob)} alt={imageAlt} />
              </div>
            ) : (
              <></>
            )}
            {bannerText}
          </div>
        </div>
      )
    }

    return list
  }, [])

  return (
    <>
      {isOpen && (
        <Popup
          content={
            lists[0].relationships?.full_screen_video
              ?.field_media_oembed_video && (
              <ReactPlayer
                url={
                  lists[0].relationships.full_screen_video
                    .field_media_oembed_video
                }
                width="100%"
                height="100%"
                config={{
                  wistia: {
                    options: {
                      videoFoam: true,
                      volumeControl: true,
                      playButton: true,
                      autoPlay: false,
                      fullscreenButton: true,
                      playbar: true,
                    },
                  },
                }}
              />
            )
          }
          handleClose={togglePopup}
        />
      )}
      <section
        id={lists[0].relationships.full_screen_video?.field_media_oembed_video.length > 0 ? "fullScreenMode" : ""}
        className={"mainBanner " + heightSmall}>
        {bannerItems[0] !== null ? (
          <Slider {...settings}> {bannerItems} </Slider>
        ) : (
          <></>
        )}
        {props.isHomepage && (
          <Link
            className="btn btnLight covid-popin"
            to={intl.formatMessage({ id: "covid_popin.link" })}
          >
            <i className="icon-info"></i>
            <em>{intl.formatMessage({ id: "covid_popin.cta_title" })}</em>
          </Link>
        )}
        <span className="scrollElem"></span>
      </section>
    </>
  )
}

export const query = graphql`
  fragment getCarouselItems on paragraph__carousel_highlight {
    relationships {
      carousel_highlight_item {
        title
        caption
        subtitle
        cta {
          title
          uri
          url
        }
        relationships {
          full_screen_video {
            field_media_oembed_video
          }
          media {
            ... on media__image {
              field_image {
                alt
              }
              internal {
                type
              }
              relationships {
                field_image {
                  gatsbyImage(breakpoints: [1920], width: 1920, formats: WEBP, layout: FULL_WIDTH)
                  bannerThumb: gatsbyImage(breakpoints: [800], width: 800, formats: WEBP, layout: FULL_WIDTH)
                }
              }
            }
            ... on media__wistia {
              internal {
                type
              }
              field_media_oembed_video
              relationships {
                thumbnail {
                  gatsbyImage(width: 1920, formats: WEBP)
                }
              }
            }
          }
        }
      }
    }
  }
`

export default MainBannerImage
